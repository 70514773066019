import React from 'react';
import {ExecutionData} from "../../../../type/ExecutionData";
import ImportPluginArgsControl from "./ImportPluginArgsControl";

export type ExecutionShortInfoControlProps = {
	execution: ExecutionData;
};

function ExecutionShortInfoControl({execution}: ExecutionShortInfoControlProps) {
	if (!execution) return <>?</>;

	return (
		<>
			{
				execution.schedule.job.jobType === 'ImportPlugin' ? (
					<ImportPluginArgsControl args={execution.arguments}/>
				) : (
					<div>
						{execution.schedule.job.jobType}
					</div>
				)
			}
		</>
	)

}

export default ExecutionShortInfoControl;
