import React from 'react';
import {Container} from "react-bootstrap";
import ExecutionMessagesControl, {ExecutionMessagesControlProps} from "../message/ExecutionMessagesControl";

function ExecutionDetailMessagesControl(props: ExecutionMessagesControlProps) {
	return (
		<Container>
			<ExecutionMessagesControl {...props} />
		</Container>
	);
}

export default ExecutionDetailMessagesControl;
