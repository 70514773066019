import React, {useEffect, useState} from 'react';
import {Badge, Button, Stack} from "react-bootstrap";
import {BasicComponentProps} from "../../../type/BasicComponentProps";
import {loadWorkers, updateWorker} from "../../../util/restClient";
import {DEFAULT_PAGING, Paging} from "../../../type/Paging";
import {clone} from "../../../util/objectUtil";
import TabMenu from "../../controls/TabMenu";
import Loading from "../../controls/Loading";
import {formatDateForHumans} from "../../../util/dateUtil";
import AdvancedTable from "../../controls/AdvancedTable";
import {WorkerData, WorkerDataPage} from "../../../type/WorkerData";
import WorkerStateControl from "../../controls/worker/WorkerStateControl";
import {BsEye, BsEyeSlash} from "react-icons/bs";

const DEFAULT_PAGING_WORKERS: Paging = clone(DEFAULT_PAGING);
DEFAULT_PAGING_WORKERS.sorting = [{name: "name"}];

function WorkersTab({eventManager}: BasicComponentProps) {
	const [workers, setWorkers] = useState<WorkerDataPage | null>(null);
	const [paging, setPaging] = useState<Paging>(clone(DEFAULT_PAGING_WORKERS));

	const search = (text: string) => {
		paging.search = text;
		setPaging(clone(paging));
	}

	const refreshWorkers = () => {
		setWorkers(null);
		loadWorkers(paging)
			.then(
				(workers: WorkerDataPage) => {
					setWorkers(workers);
				},
				(err) => eventManager.err(String(err))
			);
	};

	useEffect(refreshWorkers, [paging]);

	const toggleDashboardVisibility = (worker: WorkerData) => {
		worker.showOnDashboard = !worker.showOnDashboard;
		updateWorker(worker)
			.then(
				() => {
					refreshWorkers();
					eventManager.success(`Saved worker ${worker.name}.`);
				},
				(err) => eventManager.err(String(err))
			);
	};

	const header = [
		{name: 'id', label: 'ID'},
		{name: 'workerType', label: 'Type'},
		{name: 'name', label: 'Name'},
		{name: 'ip', label: 'IP'},
		{name: 'version', label: 'Version'},
		{name: 'workerState', label: 'State'},
		{name: 'lastHeartbeat', label: 'Heartbeat'},
		{name: 'showOnDashboard', label: 'Dashboard'},
		{name: '', label: 'Actions'}
	];

	return (
		<div>
			<TabMenu onRefreshButtonClick={refreshWorkers} onSearchButtonClick={search}>
			</TabMenu>
			<AdvancedTable
				header={header}
				onPagingChanged={setPaging}
				totalPages={workers ? workers.totalPages : 0}
				totalItems={workers ? workers.totalElements : 0}
				paging={paging}
			>
				{
					(workers === null) ? <tr>
							<td colSpan={header.length}><Loading/></td>
						</tr> :
						workers.content.map((worker, index) => {
							return (
								<tr key={index}>
									<td>{worker.id}</td>
									<td>{worker.workerType}</td>
									<td>{worker.name}</td>
									<td>{worker.ip}</td>
									<td>{worker.version}</td>
									<td><WorkerStateControl state={worker.workerState}/></td>
									<td>{formatDateForHumans(worker.lastHeartbeat)}</td>
									<td>
										{
											worker.showOnDashboard ? (
												<Badge bg="dark" text="success">Visible</Badge>
											) : (
												<Badge bg="dark" text="secondary">Hidden</Badge>
											)
										}
									</td>
									<td>
										<div className="d-flex flex-row gap-2">
											{
												worker.showOnDashboard ? (
													<Button
														variant="warning"
														size="sm"
														onClick={() => toggleDashboardVisibility(worker)}
													>
														<Stack direction="horizontal" gap={2}>
															<BsEyeSlash/>
															<div>Hide</div>
														</Stack>
													</Button>
												) : (
													<Button
														variant="success"
														size="sm"
														onClick={() => toggleDashboardVisibility(worker)}
													>
														<Stack direction="horizontal" gap={2}>
															<BsEye/>
															<div>Show</div>
														</Stack>
													</Button>
												)
											}
										</div>
									</td>
								</tr>
							);
						})
				}
			</AdvancedTable>
		</div>
	);
}

export default WorkersTab;
