import React from 'react';
import {Button, Stack, Table} from "react-bootstrap";
import {ExecutionData, ExecutionDataPage} from "../../../type/ExecutionData";
import Loading from "../../controls/Loading";
import {cancelAllExecutions, cancelExecution} from "../../../util/restClient";
import {BasicComponentProps} from "../../../type/BasicComponentProps";
import DurationControl from "../../controls/DurationControl";
import ExecutionMessagesModalButton from "../../controls/execution/message/ExecutionMessagesModalButton";
import ExecutionDetailModalButton from "../../controls/execution/detail/ExecutionDetailModalButton";
import ExecutionStateProgressControl from "../../controls/execution/state/ExecutionStateProgressControl";
import ExecutionStateControl from "../../controls/execution/state/ExecutionStateControl";
import ExecutionShortInfoControl from "../../controls/execution/short/ExecutionShortInfoControl";
import ActiveStageControl from "../../controls/execution/short/ActiveStageControl";

export type RunningExecutionsGridProps = BasicComponentProps & {
	executions: ExecutionDataPage | null;
};

function RunningExecutionsGrid({executions, eventManager}: RunningExecutionsGridProps) {

	const cancel = (execution: ExecutionData) => {
		if (!window.confirm("Really cancel this running execution?")) {
			return;
		}
		cancelExecution(execution.id)
			.then(
				() => {
					eventManager.success(`Cancelled job ${execution.schedule.job.name}.`);
				},
				(err) => eventManager.err(String(err))
			);
	};

	const cancelAll = () => {
		if (window.confirm("Really cancel all currently running executions?")) {
			cancelAllExecutions();
		}
	}


	return (
		<div>
			<Stack direction="horizontal">
				<div className="m-2">Total Running Executions: {executions?.totalElements}</div>
				<Button variant="danger" size="sm" onClick={cancelAll} disabled={executions == null || executions.totalElements <= 0}>Cancel
					All</Button>
			</Stack>

			{
				executions?.totalElements && executions?.totalElements > 0 ?
					<Table responsive bordered>
						<thead>
						<tr>
							<th>Plugin</th>
							<th>Description</th>
							<th>Job</th>
							<th>Total Duration</th>
							<th>State</th>
							<th>Progress</th>
							<th>Active Stage</th>
							<th>Errors</th>
							<th>Warnings</th>
							<th>Worker</th>
							<th>Work Duration</th>
							<th></th>
						</tr>
						</thead>
						<tbody>
						{
							(executions === null) ? <tr>
									<td colSpan={12}><Loading/></td>
								</tr> :
								executions.content.map((execution) => {
									return (
										<tr key={execution.id}>
											<td>{execution.pluginId}</td>
											<td>
												<small>
													<pre>{execution.schedule.job.description}</pre>
												</small>
											</td>
											<td><ExecutionShortInfoControl execution={execution}/></td>

											<td className="text-nowrap"><DurationControl ms={execution.durationMs}/></td>
											<td>
												<ExecutionStateControl state={execution.result.executionState}/>
											</td>
											<td>
												<ExecutionStateProgressControl
													executionState={execution.result.executionState}
													progress={execution.result.progress}
												/>
											</td>
											<td>
												{
													execution.activeStage &&
													<ActiveStageControl activeStage={execution.activeStage}/>
												}
											</td>
											<td>
												{
													execution.errors > 0 ?
														<ExecutionMessagesModalButton
															eventManager={eventManager}
															executionId={execution.id}
															defaultMessageType="Error"
															buttonLabel={String(execution.errors)}
															buttonSize={"sm"}
														/>
														: execution.errors
												}
											</td>
											<td>
												{
													execution.warnings > 0 ?
														<ExecutionMessagesModalButton
															eventManager={eventManager}
															executionId={execution.id}
															defaultMessageType="Warning"
															buttonLabel={String(execution.warnings)}
															buttonSize={"sm"}
														/>
														: execution.warnings
												}
											</td>
											<td>{execution.worker?.name}</td>
											<td><DurationControl ms={execution.durationWorkerMs}/></td>
											<td>
												<ExecutionDetailModalButton
													eventManager={eventManager}
													executionId={execution.id}
													buttonSize={"sm"}
												/>
											</td>
										</tr>
									);
								})
						}
						</tbody>
					</Table>
					: <></>
			}
		</div>
	);
}

export default RunningExecutionsGrid;
