import React from 'react';
import {Button, Stack} from 'react-bootstrap';
import {ExecutionDetailControlProps} from "./ExecutionDetailControl";
import {StringUtil} from "incomaker-react-ts-commons";
import {BsEye} from "react-icons/bs";

export type ExecutionDetailModalButtonProps = ExecutionDetailControlProps & {
	buttonLabel?: string;
	buttonSize?: "lg" | "sm";
	buttonVariant?: string;
};

function ExecutionDetailModalButton(props: ExecutionDetailModalButtonProps) {
	return (
		<>
			<div>
				<Button
					onClick={() => props.eventManager.watchExecution(props.executionId)}
					variant={StringUtil.getNonEmpty(props.buttonVariant, "primary")}
					size={props.buttonSize}
				>
					<Stack direction="horizontal" gap={2} className="align-items-center">
						<BsEye/>
						{StringUtil.getNonEmpty(props.buttonLabel, "Details...")}
					</Stack>
				</Button>
			</div>
		</>
	)
}

export default ExecutionDetailModalButton;
