import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, ButtonToolbar} from "react-bootstrap";
import {JobData, JobDataPage} from "../../../type/JobData";
import {deleteJob, loadJobs, runJobImmediately, saveJob, saveSchedule} from "../../../util/restClient";
import {BasicComponentProps} from "../../../type/BasicComponentProps";
import {DEFAULT_PAGING, Paging} from "../../../type/Paging";
import {clone} from "../../../util/objectUtil";
import TabMenu from "../../controls/TabMenu";
import {ScheduleData} from "../../../type/ScheduleData";
import AdvancedTable from "../../controls/AdvancedTable";
import Loading from "../../controls/Loading";
import JobForm from "./JobForm";
import ScheduleForm from "../scheduling/ScheduleForm";
import {getCache, setCache} from "../../../util/cacheUtil";
import JobShortInfoControl from "../../controls/JobShortInfoControl";

const DEFAULT_PAGING_JOBS: Paging = clone(DEFAULT_PAGING);
DEFAULT_PAGING_JOBS.sorting = [{name: "id", desc: true}];

function JobsTab({eventManager}: BasicComponentProps) {
	const defaultSearchText = getCache('default-search-text', () => '');
	const cachedPaging = getCache('paging-props-jobs', () => clone(DEFAULT_PAGING_JOBS));
	cachedPaging.search = defaultSearchText;
	const [paging, setPaging] = useState<Paging>(cachedPaging);

	const [jobs, setJobs] = useState<JobDataPage | null>(null);
	const [formJob, setFormJob] = useState<JobData | null>(null);
	const [formSchedule, setFormSchedule] = useState<ScheduleData | null>(null);

	const search = (text: string) => {
		const newPaging = clone(paging);
		newPaging.search = text;
		setCache('paging-props-jobs', newPaging);
		setCache('default-search-text', text)
		setPaging(newPaging);
	};

	const refreshJobs = () => {
		setJobs(null);
		loadJobs(paging)
			.then(
				setJobs,
				(err) => eventManager.err(String(err))
			);
	};

	useEffect(refreshJobs, [paging]);

	const runImmediately = (job: JobData) => {
		runJobImmediately(Number(job.id))
			.then(
				(schedule: ScheduleData) => {
					eventManager.success(`Started job ${job.name}. Spawned schedule of type Immediate (ID = ${schedule.id}).`);
				},
				(err) => eventManager.err(String(err))
			);
	};

	const editJob = (job: JobData) => {
		setFormJob(job);
	};

	const addJob = () => {
		const job: JobData = {arguments: "{}", jobType: "CleanUp", name: ""};
		editJob(job);
	};

	const onSaveJob = (data: JobData) => {
		saveJob(data)
			.then(
				() => {
					setFormJob(null);
					refreshJobs();
				},
				(err) => eventManager.err(String(err))
			);
	};

	const onDeleteJob = (job: JobData) => {
		if (!window.confirm("Really delete this job?")) {
			return;
		}
		deleteJob(Number(job.id))
			.then(
				() => {
					setFormJob(null);
					refreshJobs();
					eventManager.success(`Deleted job ${job.name}.`);
				},
				(err) => eventManager.err(String(err))
			);
	};

	const addSchedule = (job: JobData) => {
		const schedule: ScheduleData = {id: null, scheduleType: "Immediate", active: true, job: job};
		setFormSchedule(schedule);
	};

	const onSaveSchedule = (data: ScheduleData) => {
		saveSchedule(data)
			.then(
				() => {
					setFormSchedule(null);
					eventManager.success(`Added new job schedule.`);
				},
				(err) => eventManager.err(String(err))
			);
	};

	const header = [
		{name: 'id', label: 'ID'},
		{name: 'name', label: 'Name'},
		{name: 'pluginId', label: 'Plugin'},
		{name: 'description', label: 'Description'},
		{name: 'name', label: 'Job'},
		{name: 'jobType', label: 'Type'},
		{name: '', label: ''}
	];

	return (
		<div>
			<TabMenu searchText={paging.search} onRefreshButtonClick={refreshJobs} onSearchButtonClick={search}>
				<ButtonToolbar>
					<ButtonGroup>
						<Button onClick={addJob}>Add...</Button>
					</ButtonGroup>
				</ButtonToolbar>
			</TabMenu>
			<AdvancedTable
				header={header}
				onPagingChanged={setPaging}
				totalPages={jobs ? jobs.totalPages : 0}
				totalItems={jobs ? jobs.totalElements : 0}
				paging={paging}
			>
				{
					(jobs === null) ? <tr>
							<td colSpan={header.length}><Loading/></td>
						</tr> :
						jobs.content.map((job, index) => {
							return (
								<tr key={index}>
									<td>{job.id}</td>
									<td>{job.name}</td>
									<td>{job.pluginId}</td>
									<td>
										<small>
											<pre>{job.description}</pre>
										</small>
									</td>
									<td><JobShortInfoControl job={job}/></td>
									<td>{job.jobType}</td>
									<td>
										<div className="d-flex gap-2">
											<Button variant="success" size="sm" onClick={() => runImmediately(job)}>Run&nbsp;Now</Button>
											<Button variant="primary" size="sm" onClick={() => addSchedule(job)}>+&nbsp;Schedule...</Button>
											<Button variant="primary" size="sm" onClick={() => editJob(job)}>Edit...</Button>
										</div>
									</td>
								</tr>
							);
						})
				}
			</AdvancedTable>
			{
				formJob ?
					<JobForm
						data={formJob}
						onSaved={onSaveJob}
						onCancelled={() => setFormJob(null)}
						onDeleted={onDeleteJob}
						eventManager={eventManager}
					/>
					:
					formSchedule ?
						<ScheduleForm
							data={formSchedule}
							onSaved={onSaveSchedule}
							onCancelled={() => setFormSchedule(null)}
							eventManager={eventManager}
						/>
						: <></>
			}

		</div>
	);
}

export default JobsTab;
