import React, {useCallback, useEffect, useState} from 'react';
import {ControlWithExecutionIdProps} from "../../../../type/BasicComponentProps";
import {loadExecutionMessages} from "../../../../util/restClient";
import {DEFAULT_PAGING, Paging} from "../../../../type/Paging";
import {clone} from "../../../../util/objectUtil";
import Loading from "../../Loading";
import {formatDateForHumans} from "../../../../util/dateUtil";
import AdvancedTable from "../../AdvancedTable";
import {ExecutionMessageDataPage} from "../../../../type/ExecutionMessageData";
import SmartTextBox from "../../SmartTextBox";
import {Form, Stack} from "react-bootstrap";

const DEFAULT_PAGING_ERRORS: Paging = clone(DEFAULT_PAGING);
DEFAULT_PAGING_ERRORS.sorting = [{name: "timestamp", desc: true}];
DEFAULT_PAGING_ERRORS.size = 5;

export type ExecutionMessagesControlProps = ControlWithExecutionIdProps & {
	defaultMessageType?: string | null;
};

function ExecutionMessagesControl({eventManager, executionId, defaultMessageType}: ExecutionMessagesControlProps) {
	const [messageType, setMessageType] = useState<string | null | undefined>(defaultMessageType);
	const [messages, setMessages] = useState<ExecutionMessageDataPage | null>(null);
	const [paging, setPaging] = useState<Paging>(clone(DEFAULT_PAGING_ERRORS));

	const refreshMessages = useCallback(
		() => {
			setMessages(null);
			loadExecutionMessages(executionId, messageType, paging)
				.then(
					(executions: ExecutionMessageDataPage) => {
						setMessages(executions);
					},
					(err) => eventManager.err(String(err))
				);
		},
		[eventManager, executionId, messageType, paging]
	);

	useEffect(refreshMessages, [paging, executionId, messageType]);

	const header = [
		{name: 'timestamp', label: 'Time'},
		{name: 'messageType', label: 'Type'},
		{name: 'message', label: 'Message'}
	];

	return (
		<div>
			<Stack direction="horizontal" gap={2} className="pb-3">
				Message type:
				<Form.Select
					className="w-auto"
					value={String(messageType)}
					onChange={(e) => setMessageType(e.target.value)}
				>
					<option value="">All</option>
					<option value="Error">Error</option>
					<option value="Warning">Warning</option>
				</Form.Select>
			</Stack>
			<AdvancedTable
				header={header}
				onPagingChanged={setPaging}
				totalPages={messages ? messages.totalPages : 0}
				totalItems={messages ? messages.totalElements : 0}
				paging={paging}
			>
				{
					(messages === null) ? <tr>
							<td colSpan={header.length}><Loading/></td>
						</tr> :
						messages.content.map((error, index) => {
							return (
								<tr key={index}>
									<td>{formatDateForHumans(error.timestamp)}</td>
									<td>{error.messageType}</td>
									<td><SmartTextBox text={error.message}/></td>
								</tr>
							);
						})
				}
			</AdvancedTable>
		</div>
	);
}

export default ExecutionMessagesControl;
