import React, {useCallback, useEffect, useState} from 'react';
import {Button, Toast} from "react-bootstrap";
import {BsFillSquareFill} from 'react-icons/bs';
import {BasicComponentProps} from "../../type/BasicComponentProps";
import {StringUtil, UserAlert, UserAlerts} from "incomaker-react-ts-commons";

function AlertsBox({eventManager}: BasicComponentProps) {
	const [userAlerts] = useState<UserAlerts>(new UserAlerts());
	const [alerts, setAlerts] = useState<Array<UserAlert>>([]);

	const resetAlerts = useCallback(
		() => {
			userAlerts.reset();
		},
		[userAlerts]
	);

	const addAlert = useCallback(
		(alert: UserAlert) => {
			userAlerts.add(alert);
		},
		[userAlerts]
	);

	const removeAlert = useCallback(
		(alert: UserAlert) => {
			userAlerts.remove(alert);
		},
		[userAlerts]
	);

	const alertsChanged = useCallback(
		() => {
			setAlerts([...userAlerts.alerts]);
		},
		[userAlerts, setAlerts]
	);

	useEffect(() => {
		userAlerts.addOnChangeHandler(alertsChanged);
		return () => {
			userAlerts.removeOnChangeHandler(alertsChanged);
		}
	}, [userAlerts, alertsChanged]);

	useEffect(() => {
		eventManager.addAlertListener(addAlert);
		return () => {
			eventManager.removeAlertListener(addAlert);
		}
	}, [eventManager, addAlert]);

	return (
		<div className="alert-box">
			{
				alerts.map(
					(alert: UserAlert, index) => {
						return (
							<Toast
								bg={alert.type}
								onClose={() => removeAlert(alert)}
								className="m-2"
							>
								{
									StringUtil.notEmpty(alert.title) && (
										<Toast.Header className="justify-content-between">
											<BsFillSquareFill/>
											<strong className="ms-2 me-auto">{alert.title}</strong>
										</Toast.Header>
									)
								}
								<Toast.Body>{alert.message}</Toast.Body>
							</Toast>
						)
					}
				)
			}
			{
				alerts.length > 0 && (
					<div className="d-flex justify-content-center m-2">
						<Button size="sm" variant="secondary" onClick={resetAlerts}>Clear All</Button>
					</div>
				)
			}
		</div>
	);
}

export default AlertsBox;
