import React from 'react';
import Loading, {LoadingProps} from "./Loading";


function LoadingBlock({message}: LoadingProps) {
	return (
		<div className="d-flex w-100 p-3 m-3 align-items-center justify-content-center">
			<Loading message={message}/>
		</div>
	);
}

export default LoadingBlock;
