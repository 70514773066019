import React from 'react';
import {Stack} from 'react-bootstrap';
import {getStateVariant, isStateRunning} from "./ExecutionStateControl";
import ProgressBarControl from "../../ProgressBarControl";
import {StackDirection} from "react-bootstrap/Stack";
import PercentProgressControl from "../../PercentProgressControl";

export type ExecutionStateProgressControlProps = {
	executionState: string;
	progress?: number | null;
	direction?: StackDirection;
	gap?: number;
	height?: number;
};

function ExecutionStateProgressControl({gap, height, progress, executionState, direction = 'horizontal'}: ExecutionStateProgressControlProps) {
	const actualGap = gap === undefined ? 1 : gap;
	return <Stack direction={direction} gap={actualGap}>
		<PercentProgressControl progress={progress}/>
		<div className="flex-grow-1" style={{minWidth: 100}}>
			<ProgressBarControl
				progress={progress}
				animated={isStateRunning(executionState)}
				variant={getStateVariant(executionState)}

			/>
		</div>
	</Stack>
}

export default ExecutionStateProgressControl;
