import React from 'react';
import {Badge, Stack} from "react-bootstrap";
import {ExecutionStageData} from "../../../../type/ExecutionStageData";
import ExecutionStateProgressControl from "../state/ExecutionStateProgressControl";

export type ActiveStageControlProps = {
	activeStage: ExecutionStageData;
};

function ActiveStageControl({activeStage}: ActiveStageControlProps) {
	if (!activeStage) return <></>;

	return (
		<small className="text-muted">
			<Stack direction="vertical" className="align-items-center" gap={1}>
				<Badge bg="light" text="dark">{activeStage.stageName}</Badge>
				<ExecutionStateProgressControl
					executionState={activeStage.executionState}
					progress={activeStage.progress}
				/>
			</Stack>
		</small>
	)

}

export default ActiveStageControl;
