import {EventManager, UserAlert} from "incomaker-react-ts-commons";
import {ImportPluginJobArgument} from "../type/ImportPluginJobArgument";
import {ScheduleData} from "../type/ScheduleData";

export class JmEventManager extends EventManager {

	createAlert(message: string, type: string | undefined = 'danger', title: string | undefined = undefined) {
		const alert = {
			time: new Date(),
			message: message,
			type: type,
			title: title
		}
		this.triggerEvent('alert', alert);
	}

	err(message: string) {
		this.createAlert(message, 'danger');
	}

	warn(message: string) {
		this.createAlert(message, 'warning');
	}

	success(message: string) {
		this.createAlert(message, 'success');
	}

	addAlertListener(handler: (alert: UserAlert) => any) {
		this.addEventListener('alert', handler);
	}

	removeAlertListener(handler: (alert: UserAlert) => any) {
		this.removeEventListener('alert', handler);
	}

	// WATCH EXECUTION

	watchExecution(executionId: number) {
		this.triggerEvent('watch-execution', executionId);
	}

	unwatchExecution() {
		this.triggerEvent('watch-execution', undefined);
	}

	addWatchExecutionListener(handler: (executionId?: number) => any) {
		this.addEventListener('watch-execution', handler);
	}

	removeWatchExecutionListener(handler: (executionId?: number) => any) {
		this.removeEventListener('watch-execution', handler);
	}

	// IMPORT FORM

	showImportForm(form: ImportPluginJobArgument) {
		this.triggerEvent('import-form', form);
	}

	addShowImportFormListener(handler: (form: ImportPluginJobArgument) => any) {
		this.addEventListener('import-form', handler);
	}

	removeShowImportFormListener(handler: (form: ImportPluginJobArgument) => any) {
		this.removeEventListener('import-form', handler);
	}

	// CUSTOM IMPORT START

	customImportStarted(schedule: ScheduleData) {
		this.success('Custom plugin import started.');
		this.triggerEvent('custom-import-started', schedule);
	}

	addCustomImportStartedListener(handler: (schedule: ScheduleData) => any) {
		this.addEventListener('custom-import-started', handler);
	}

	removeCustomImportStartedListener(handler: (schedule: ScheduleData) => any) {
		this.removeEventListener('custom-import-started', handler);
	}

}
