import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card, Col, Nav, Row, Stack} from "react-bootstrap";
import {ControlWithExecutionIdProps} from "../../../../type/BasicComponentProps";

import {BsArrowClockwise, BsXCircle} from 'react-icons/bs';
import {cancelExecution, loadExecutionData, runJobImmediately} from "../../../../util/restClient";
import {isStateRunning} from "../state/ExecutionStateControl";
import {ExecutionData} from '../../../../type/ExecutionData';
import LoadingBlock from "../../LoadingBlock";
import ExecutionResultProgressControl from "../result/ExecutionResultProgressControl";
import ExecutionDetailInfoControl from "./ExecutionDetailInfoControl";
import ExecutionDetailStagesControl from "./ExecutionDetailStagesControl";
import ExecutionDetailMessagesControl from "./ExecutionDetailMessagesControl";
import {ScheduleData} from "../../../../type/ScheduleData";
import ExecutionShortInfoControl from "../short/ExecutionShortInfoControl";

export type ExecutionDetailControlProps = ControlWithExecutionIdProps & {};

function ExecutionDetailControl({executionId, eventManager}: ExecutionDetailControlProps) {
	const [activeTab, setActiveTab] = useState<string>('stages');
	const [execution, setExecution] = useState<ExecutionData | null>(null);

	const reloadAll = useCallback(
		() => {
			loadExecutionData(executionId)
				.then(
					setExecution,
					(err) => eventManager.err(String(err))
				);
		},
		[executionId, eventManager]
	);

	useEffect(
		() => {
			reloadAll();
			const handler = setInterval(reloadAll, 3000);
			return () => {
				clearInterval(handler);
			}
		},
		[reloadAll]
	);

	const restartJob = useCallback(
		() => {
			const jobId = execution?.schedule.job.id;
			console.log(execution);
			if (jobId !== undefined && jobId !== null && !isNaN(jobId))
				runJobImmediately(jobId)
					.then(
						(schedule: ScheduleData) => {
							eventManager.unwatchExecution();
							eventManager.customImportStarted(schedule);
						},
						(err) => eventManager.err(String(err))
					)
		},
		[execution]
	);

	const showImportForm = () => {
		if (!execution) return;
		eventManager.unwatchExecution();
		eventManager.showImportForm(JSON.parse(execution.arguments));
	}

	const cancel = () => {
		if (!window.confirm("Really cancel this running execution?")) {
			return;
		}
		cancelExecution(executionId).then(reloadAll);
	}

	const tabChanged = (t: string | null) => {
		setActiveTab(String(t));
	}

	if (!execution) {
		return <LoadingBlock/>;
	}

	return (
		<Card>
			<Card.Body className="p-0">
				<div className="p-3 pb-0">
					<Card.Title>{execution.schedule.job.name}</Card.Title>
					<Stack gap={2}>
						<div>
							<ExecutionShortInfoControl execution={execution}/>
						</div>
						<div>
							<ExecutionResultProgressControl executionResult={execution.result} direction="horizontal" gap={3}/>
						</div>
						<Row>
							<Col>
								{
									execution.worker && (
										<Stack direction="horizontal" gap={2}>
											<strong>{execution.worker.name}</strong>
											{
												isStateRunning(execution.result.executionState) && (
													<div>- {execution.worker.currentActivity}</div>
												)
											}
										</Stack>
									)
								}
							</Col>
							<Col>
								<Stack direction="horizontal" gap={2} className="justify-content-end">
									{
										isStateRunning(execution.result.executionState) ? (
											<Button
												className="d-flex align-items-center"
												variant="warning"
												size="sm"
												onClick={cancel}
											>
												<BsXCircle className="me-2"/>
												Cancel
											</Button>
										) : (
											<>
												{
													(execution.schedule.job.jobType === 'ImportPlugin') && (
														<Button
															className="d-flex align-items-center"
															variant="primary"
															size="sm"
															onClick={showImportForm}
														>
															Edit & run...
														</Button>
													)
												}
												<Button
													className="d-flex align-items-center"
													variant="success"
													size="sm"
													onClick={restartJob}
												>
													<BsArrowClockwise className="me-2"/>
													Restart
												</Button>
											</>
										)
									}
								</Stack>
							</Col>
						</Row>
					</Stack>
				</div>
				<Nav
					variant="tabs"
					defaultActiveKey={activeTab}
					onSelect={tabChanged}
					className="mb-1"
				>
					<Nav.Item className="ms-3">
						<Nav.Link eventKey="stages">Stages</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="info">Info</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="messages">Messages</Nav.Link>
					</Nav.Item>
				</Nav>
				<div className="p-1">
					{
						activeTab === 'info' && (
							execution ? <ExecutionDetailInfoControl execution={execution}/> : <LoadingBlock/>
						)
					}
					{
						activeTab === 'messages' && <ExecutionDetailMessagesControl eventManager={eventManager} executionId={executionId}/>
					}
					{
						activeTab === 'stages' && (
							<ExecutionDetailStagesControl
								eventManager={eventManager}
								executionId={executionId}
								defaultText={execution?.result.statusText}
							/>
						)
					}
				</div>
			</Card.Body>
		</Card>
	);
}

export default ExecutionDetailControl;
