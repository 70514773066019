export const DEFAULT_PAGE_SIZE = 50;

export const DEFAULT_PAGING = {page: 0, size: DEFAULT_PAGE_SIZE, sorting: []}

export type SortingField = {
	name: string;
	desc?: boolean;
};

export type Sorting = Array<SortingField>;

export type Paging = {
	page: number;
	size: number;
	search?: string;
	sorting: Sorting;
};

export type Page<Type> = {
	totalPages: number;
	totalElements: number;
	number: number;
	content: Type;
};

export type PagingRequest = {
	page: number;
	size: number;
	search?: string;
	sorting?: string;
};

export type PagingProps = {
	paging: Paging;
};
