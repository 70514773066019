import {ByteUtil} from 'incomaker-react-ts-commons';
import React from 'react';
import {OverlayTrigger, ProgressBar, Tooltip} from "react-bootstrap";
import {OverlayInjectedProps} from 'react-bootstrap/esm/Overlay';
import {JavaHeapStats} from "../../../type/WorkerData";

export type WorkerJavaHeapControlProps = {
	stats?: JavaHeapStats;
};

function WorkerJavaHeapControl({stats}: WorkerJavaHeapControlProps) {
	if (!stats) return <>?</>

	const size = stats.heapSize;
	const max = stats.heapMaxSize;
	const free = stats.heapFreeSize;
	const used = size - free;

	const renderTooltip = (props: OverlayInjectedProps) => (
		<Tooltip {...props}>
			<div className="text-small bg-light">
				<u><strong>Java Heap</strong></u>
				<div className="text-left bg-secondary">
					<div className="bg-light ms-1 ps-1">
						<div>Max: <strong>{ByteUtil.formatByteSize(max)}</strong></div>
						<div>Size: <strong>{ByteUtil.formatByteSize(size)}</strong></div>
						<div className="bg-warning">
							<div className="bg-light ms-1 ps-1">Used: <strong>{ByteUtil.formatByteSize(used)}</strong></div>
						</div>
						<div className="bg-success">
							<div className="bg-light ms-1 ps-1">Free: <strong>{ByteUtil.formatByteSize(free)}</strong></div>
						</div>
					</div>
				</div>
			</div>
		</Tooltip>
	);

	return (
		<OverlayTrigger
			placement="top"
			delay={{show: 250, hide: 400}}
			overlay={renderTooltip}
		>
			<small>
				<ProgressBar min={0} max={max}>
					<ProgressBar variant="warning" min={0} now={used} max={max}/>
					<ProgressBar variant="success" min={0} now={free} max={max}/>
				</ProgressBar>
			</small>
		</OverlayTrigger>
	);
}

export default WorkerJavaHeapControl;
