export function isEmpty(str: string | null | undefined): boolean {
	return str === null || str === undefined || str.trim().length === 0;
}

export function notEmpty(str: string | null | undefined): boolean {
	return !isEmpty(str);
}

export function containsLineBreaks(str: string | null | undefined): boolean {
	if (str === null || str === undefined || str.trim().length === 0) return false;
	return str.includes("\n");
}

export function safeTruncate(str: string | null | undefined, len: number): string {
	if (isEmpty(str) || !str) return '';
	if (str.length <= len) String(str);
	return str.substring(0, len);
}

export function safeTrim(str: string | null | undefined): string {
	if (isEmpty(str) || !str) return '';
	return str.trim();
}
