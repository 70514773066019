const AUTH_STORAGE_KEY = 'job_manager_credentials';

export type AuthStore = {
	auth: Auth | null;
	checked: boolean;
};

export type Auth = {
	apiKey: string;
};

const JOB_MANAGER_CREDENTIALS: AuthStore = {
	auth: null,
	checked: false
};

function loadAuthFromStorage(): Auth | null {
	const str = localStorage.getItem(AUTH_STORAGE_KEY);
	if (str === null) return null;
	return JSON.parse(str);
}

function saveAuthToStorage(credentials: Auth | null) {
	if (credentials === null) {
		localStorage.removeItem(AUTH_STORAGE_KEY);
		return;
	}
	localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(credentials));
}

export function getAuthentication(): Auth | null {
	if (JOB_MANAGER_CREDENTIALS.auth === null && !JOB_MANAGER_CREDENTIALS.checked) {
		JOB_MANAGER_CREDENTIALS.auth = loadAuthFromStorage();
		JOB_MANAGER_CREDENTIALS.checked = true;
	}
	return JOB_MANAGER_CREDENTIALS.auth;
}

export function setAuthentication(credentials: Auth | null) {
	JOB_MANAGER_CREDENTIALS.auth = credentials;
	saveAuthToStorage(credentials);
}

export function isAuthAvailable(): boolean {
	return getAuthentication() !== null;
}
