import React from 'react';
import {containsLineBreaks} from "../../util/stringUtil";

export type SmartTextBoxProps = {
	text?: string | null;
};

function SmartTextBox({text}: SmartTextBoxProps) {
	const hasLineBreaks = containsLineBreaks(text);
	return (
		<div className="smart-text-box">
			{
				hasLineBreaks ?
					<pre>{text}</pre>
					: <div>{text}</div>
			}
		</div>
	);
}

export default SmartTextBox;
