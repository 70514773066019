import React from 'react';
import {Stack} from "react-bootstrap";
import ImportPluginArgsControl from "./execution/short/ImportPluginArgsControl";
import {JobData} from "../../type/JobData";

export type ScheduleShortInfoControlProps = {
	job: JobData;
};

function JobShortInfoControl({job}: ScheduleShortInfoControlProps) {
	if (!job) return <>?</>;

	return (
		<>
			{
				job.jobType === 'ImportPlugin' ? (
					<ImportPluginArgsControl args={job.arguments}/>
				) : (
					<Stack>
						<strong>{job.name}</strong>
						<small>{job.jobType}</small>
					</Stack>
				)
			}
		</>
	)

}

export default JobShortInfoControl;
