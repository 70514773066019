import React from 'react';

export type PercentProgressControlProps = {
	progress?: number | null;
};

function PercentProgressControl({progress}: PercentProgressControlProps) {
	const percent = (progress === null || progress === undefined) ? null : Math.round(progress * 100);
	const percentLabel = percent === null ? '?' : `${percent}%`;
	return <span>{percentLabel}</span>
}

export default PercentProgressControl;
