import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import ExecutionDetailControl, {ExecutionDetailControlProps} from "./ExecutionDetailControl";

export type ExecutionDetailModalProps = ExecutionDetailControlProps & {
	closeModalRequested: () => any;
};

function ExecutionDetailModal(props: ExecutionDetailModalProps) {
	return (
		<Modal
			show={true}
			onHide={props.closeModalRequested}
			size="xl"
		>
			<Modal.Body>
				<ExecutionDetailControl
					{...props}
				/>
			</Modal.Body>
			<Modal.Footer className="justify-content-center">
				<Button
					onClick={props.closeModalRequested}
					variant="secondary"
					className="m-auto"
				>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default ExecutionDetailModal;
