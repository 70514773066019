import React, {FormEvent, PropsWithChildren, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {ButtonToolbar, Form} from "react-bootstrap";
import {BsArrowClockwise} from "react-icons/bs";
import TextInputWithReset from "./TextInputWithReset";
import {safeTrim} from "../../util/stringUtil";

export type TabMenuProps = {
	searchText?: string;
	onRefreshButtonClick: () => any;
	onSearchButtonClick?: (s: string) => any;
};

function TabMenu({children, searchText, onSearchButtonClick, onRefreshButtonClick}: PropsWithChildren<TabMenuProps>) {
	const [currentSearch, setCurrentSearch] = useState<string>(safeTrim(searchText))

	const search = (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();
		// @ts-ignore
		onSearchButtonClick(currentSearch);
	}

	const onReset = () => {
		setCurrentSearch('');
		// @ts-ignore
		onSearchButtonClick('');
	}

	return (
		<div className="d-flex flex-wrap align-items-center pb-3 gap-2">
			{
				children && (
					<div>
						{children}
					</div>
				)
			}
			<div>
				{
					onSearchButtonClick === undefined ? (
						<div>
							<ButtonToolbar>
								<Button variant="success" className="d-flex align-items-center" onClick={onRefreshButtonClick}>
									<BsArrowClockwise className="me-1"/>
									<div>Refresh</div>
								</Button>
							</ButtonToolbar>
						</div>
					) : (
						<Form onSubmit={search} className="d-flex align-items-center">
							<span className="me-2">Search:</span>
							<div className="flex-grow-1 me-2">
								<TextInputWithReset value={currentSearch} onChange={setCurrentSearch} onReset={onReset}></TextInputWithReset>
							</div>
							<div>
								<Button
									type="button"
									onClick={search}
									variant="success"
									className="d-flex align-items-center"
								>
									<BsArrowClockwise className="me-1"/>
									<div>Refresh</div>
								</Button>
							</div>
						</Form>
					)
				}
			</div>
		</div>
	);
}

export default TabMenu;
