import React, {useEffect, useState} from 'react';
import {loadAsyncCallbacks} from "../../../util/restClient";
import {BasicComponentProps} from "../../../type/BasicComponentProps";
import {DEFAULT_PAGING, Paging} from "../../../type/Paging";
import TabMenu from "../../controls/TabMenu";
import AdvancedTable from "../../controls/AdvancedTable";
import Loading from "../../controls/Loading";
import {AsyncCallbackDataPage} from "../../../type/AsyncCallbackData";
import {clone} from '../../../util/objectUtil';
import DurationControl from "../../controls/DurationControl";
import {formatDateForHumans} from "../../../util/dateUtil";
import AsyncCallbackStateControl from "./AsyncCallbackStateControl";
import SmartTextBox from "../../controls/SmartTextBox";
import ExecutionStateControl from "../../controls/execution/state/ExecutionStateControl";
import ExecutionDetailModalButton from "../../controls/execution/detail/ExecutionDetailModalButton";

const DEFAULT_PAGING_CALLBACKS: Paging = clone(DEFAULT_PAGING);
DEFAULT_PAGING_CALLBACKS.sorting = [{name: "createTime", desc: true}];

function AsyncCallbacksTab({eventManager}: BasicComponentProps) {
	const [callbacks, setCallbacks] = useState<AsyncCallbackDataPage | null>(null);
	const [paging, setPaging] = useState<Paging>(clone(DEFAULT_PAGING_CALLBACKS));

	const refreshCallbacks = () => {
		setCallbacks(null);
		loadAsyncCallbacks(paging)
			.then(
				(data: AsyncCallbackDataPage) => {
					setCallbacks(data);
				},
				(err) => eventManager.err(String(err))
			);
	};

	useEffect(refreshCallbacks, [paging]);

	const header = [
		{name: 'execution.pluginId', label: 'Plugin'},
		{name: 'execution.executionState', label: 'Execution State'},
		{name: 'execution.worker.name', label: 'Worker'},
		{name: 'createTime', label: 'Start'},
		{name: 'durationMs', label: 'Duration'},
		{name: 'state', label: 'Callback State'},
		{name: 'result', label: 'Result'},
		{name: '', label: ''}
	];

	return (
		<div>
			<TabMenu onRefreshButtonClick={refreshCallbacks}>
			</TabMenu>
			<AdvancedTable
				header={header}
				onPagingChanged={setPaging}
				totalPages={callbacks ? callbacks.totalPages : 0}
				totalItems={callbacks ? callbacks.totalElements : 0}
				paging={paging}
			>
				{
					(callbacks === null) ? <tr>
							<td colSpan={header.length}><Loading/></td>
						</tr> :
						callbacks.content.map((data, index) => {
							return (
								<tr key={index}>
									<td>{data.execution.pluginId}</td>
									<td><ExecutionStateControl state={data.execution.result.executionState}/></td>
									<td>{data.execution.worker?.name}</td>
									<td>{formatDateForHumans(data.createTime)}</td>
									<td><DurationControl ms={data.durationMs}/></td>
									<td><AsyncCallbackStateControl state={data.state}/></td>
									<td>
										<SmartTextBox text={data.result}/>
									</td>
									<td>
										<ExecutionDetailModalButton
											executionId={data.execution.id}
											eventManager={eventManager}
											buttonSize="sm"
										/>
									</td>
								</tr>
							);
						})
				}
			</AdvancedTable>
		</div>
	);
}

export default AsyncCallbacksTab;
