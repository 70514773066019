import React, {FormEvent, useEffect, useRef, useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import {BasicComponentProps} from "../../../type/BasicComponentProps";
import {searchPlugin} from "../../../util/restClient";
import Loading from "../../controls/Loading";
import AdvancedTable from '../../controls/AdvancedTable';
import {Paging} from "../../../type/Paging";
import {clone} from "../../../util/objectUtil";
import {Plugin, PluginPage} from "../../../type/Plugin";
import {CancellablePromise} from "incomaker-react-ts-commons";

const PAGE_SIZE: number = 10;

export type PluginSearchFormProps = BasicComponentProps & {
	onConfirmed: (pluginId: number) => any;
	onCancelled: () => any;
};

function PluginSearchForm({onConfirmed, onCancelled, eventManager}: PluginSearchFormProps) {
	const [paging, setPaging] = useState<Paging>({search: '', page: 0, size: PAGE_SIZE, sorting: [{name: 'id', desc: true}]});
	const [loading, setLoading] = useState<boolean>(false);
	const [results, setResults] = useState<PluginPage | null>(null);
	const [loadingPromise, setLoadingPromise] = useState<CancellablePromise | null>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	const searchTermChanged = (text: string) => {
		paging.page = 0;
		paging.search = text;
		setPaging(clone(paging));
	}

	const loadPlugins = () => {
		if ((!paging.search) || (paging.search.length < 3)) {
			setLoading(false);
			return;
		}
		if (loadingPromise) {
			loadingPromise.cancel();
		}
		setLoading(true);

		const lpp = new CancellablePromise(searchPlugin(paging));
		lpp.promise.then(
			(results: PluginPage) => {
				setResults(results);
				setLoading(false);
			}
		).catch(
			(err) => {
				setLoading(false);
				eventManager.err(String(err));
			}
		);
		setLoadingPromise(lpp);
	}

	useEffect(loadPlugins, [paging]);

	const onFormSubmit = (e: FormEvent) => {
		e.preventDefault();
		loadPlugins();
	}

	const header = [
		{name: 'id', label: 'Plugin ID'},
		{name: 'pluginStatus', label: 'Status'},
		{name: 'account.id', label: 'Account ID'},
		{name: 'account.name', label: 'Account Name'},
		{name: 'account.accountInfo.companyShortName', label: 'Company'},
		{name: 'url', label: 'URL'},
		{name: 'platform.name', label: 'Platform'}
	];

	return (
		<Modal size="xl" show={true} onHide={onCancelled} onEntered={() => inputRef?.current?.focus()}>
			<Modal.Header closeButton>
				<Modal.Title>Find Plugin</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={onFormSubmit}>
					<Form.Group className="mb-3" controlId="id">
						<Form.Label>Search:</Form.Label>
						<Form.Control
							type="text"
							ref={inputRef}
							value={paging.search}
							onChange={(e) => searchTermChanged(e.target.value)}>
						</Form.Control>
						<Form.Text muted>
							Min. 3 characters.
						</Form.Text>
					</Form.Group>
				</Form>
				{
					<AdvancedTable
						header={header}
						onPagingChanged={setPaging}
						totalPages={results ? results.totalPages : 0}
						totalItems={results ? results.totalElements : 0}
						paging={paging}
					>
						{
							results ?
								results.content.map(
									(result: Plugin, index: number) =>
										<tr key={index} className="cursor-pointer" onClick={() => onConfirmed(Number(result.id))}>
											<td>{result.id}</td>
											<td>{result.pluginStatus}</td>
											<td>{result.account?.id}</td>
											<td>{result.account?.name}</td>
											<td>{result.account?.accountInfo?.companyShortName}</td>
											<td>{result.url}</td>
											<td>{result.platform?.name}</td>
										</tr>
								)
								: <></>
						}
					</AdvancedTable>
				}
				{
					loading && <Loading/>
				}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onCancelled}>Close</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default PluginSearchForm;
