import React, {useEffect, useState} from 'react';
import {BasicComponentProps} from "../../../type/BasicComponentProps";
import {ExecutionData, ExecutionDataPage} from "../../../type/ExecutionData";
import {cancelExecution, loadExecutions, loadExecutionStates, runJobImmediately} from "../../../util/restClient";
import {DEFAULT_PAGING, Paging} from "../../../type/Paging";
import {clone} from "../../../util/objectUtil";
import TabMenu from "../../controls/TabMenu";
import Loading from "../../controls/Loading";
import {formatDateForHumans} from "../../../util/dateUtil";
import DurationControl from "../../controls/DurationControl";
import AdvancedTable from "../../controls/AdvancedTable";
import {Form} from 'react-bootstrap';
import SmartTextBox from "../../controls/SmartTextBox";
import ExecutionMessagesModalButton from "../../controls/execution/message/ExecutionMessagesModalButton";
import {JobData} from "../../../type/JobData";
import {ScheduleData} from "../../../type/ScheduleData";
import {getCache, setCache} from "../../../util/cacheUtil";
import ExecutionDetailModalButton from "../../controls/execution/detail/ExecutionDetailModalButton";
import ExecutionStateControl from "../../controls/execution/state/ExecutionStateControl";
import ExecutionStateProgressControl from "../../controls/execution/state/ExecutionStateProgressControl";
import ExecutionShortInfoControl from "../../controls/execution/short/ExecutionShortInfoControl";

export type ExecutionsPagingProps = {
	paging: Paging;
	executionState: string;
};

const DEFAULT_PAGING_EXECUTIONS: Paging = clone(DEFAULT_PAGING);
DEFAULT_PAGING_EXECUTIONS.sorting = [{name: "startTime", desc: true}];

const DEFAULT_PAGING_PROPS: ExecutionsPagingProps = {
	paging: clone(DEFAULT_PAGING_EXECUTIONS),
	executionState: ''
};

function ExecutionsTab({eventManager}: BasicComponentProps) {
	const defaultSearchText = getCache('default-search-text', () => '');
	const cachedPagingProps = getCache('paging-props-execution', () => clone(DEFAULT_PAGING_PROPS));
	cachedPagingProps.paging.search = defaultSearchText;
	const [pagingProps, setPagingProps] = useState<ExecutionsPagingProps>(cachedPagingProps);

	const [executions, setExecutions] = useState<ExecutionDataPage | null>(null);
	const [executionStates, setExecutionStates] = useState<string[] | null>(null);

	const refreshPagingProps = () => {
		const newProps = clone(pagingProps);
		setCache('paging-props-execution', newProps);
		setPagingProps(newProps);
	}

	const setPaging = (paging: Paging) => {
		pagingProps.paging = clone(paging);
		refreshPagingProps();
	}

	const refreshPaging = () => {
		setPaging(pagingProps.paging);
	}

	const setSearchText = (text: string) => {
		pagingProps.paging.search = text;
		pagingProps.paging.page = 0;
		setCache('default-search-text', text)
		refreshPaging();
	}

	const setSelectedState = (state: string) => {
		pagingProps.executionState = state;
		pagingProps.paging.page = 0;
		refreshPaging();
	}

	const loadStates = () => {
		loadExecutionStates()
			.then(
				setExecutionStates,
				(err) => eventManager.err(String(err))
			);
	}

	useEffect(loadStates, []);

	const refreshExecutions = () => {
		setExecutions(null);
		loadExecutions(pagingProps.paging, pagingProps.executionState)
			.then(
				setExecutions,
				(err) => eventManager.err(String(err))
			);
	};

	useEffect(refreshExecutions, [pagingProps]);

	const runImmediately = (job: JobData) => {
		runJobImmediately(Number(job.id))
			.then(
				(schedule: ScheduleData) => {
					eventManager.success(`Started job ${job.name}. Spawned schedule of type Immediate (ID = ${schedule.id}).`);
				},
				(err) => eventManager.err(String(err))
			);
	};

	const header = [
		{name: 'id', label: 'ID'},
		{name: 'pluginId', label: 'Plugin'},
		{name: 'schedule.job.description', label: 'Description'},
		{name: 'schedule.job.name', label: 'Job'},
		{name: 'startTime', label: 'Start Time'},
		{name: 'durationMs', label: 'Total Duration'},
		{name: 'executionState', label: 'State'},
		{name: 'progress', label: 'Progress'},
		{name: 'errors', label: 'Errors'},
		{name: 'warnings', label: 'Warnings'},
		{name: 'worker.name', label: 'Worker'},
		{name: 'startWorkerTime', label: 'Worker Started'},
		{name: 'durationWorkerMs', label: 'Work Duration'},
		{name: '', label: ''},
	];

	const cancel = (execution: ExecutionData) => {
		if (!window.confirm("Really cancel this running execution?")) {
			return;
		}
		cancelExecution(execution.id)
			.then(
				() => {
					eventManager.success(`Cancelled job ${execution.schedule.job.name}.`);
				},
				(err) => eventManager.err(String(err))
			);
	};

	return (
		<div>
			<TabMenu searchText={pagingProps.paging.search} onRefreshButtonClick={refreshExecutions} onSearchButtonClick={setSearchText}>
				<div className="d-flex align-items-center">
					<span className="me-2">State:</span>
					<Form.Select
						value={pagingProps.executionState}
						onChange={(e) => setSelectedState(e.target.value)}
					>
						{
							executionStates ?
								<>
									<option></option>
									{executionStates.map((jt) => <option key={jt} value={jt}>{jt}</option>)}
								</> : <option>loading...</option>
						}
					</Form.Select>
				</div>
			</TabMenu>
			<AdvancedTable
				header={header}
				onPagingChanged={setPaging}
				totalPages={executions ? executions.totalPages : 0}
				totalItems={executions ? executions.totalElements : 0}
				paging={pagingProps.paging}
			>
				{
					(executions === null) ? <tr>
							<td colSpan={header.length}><Loading/></td>
						</tr> :
						executions.content.map((execution, index) => {
							return (
								<tr key={index}>
									<td>{execution.id}</td>
									<td>{execution.pluginId}</td>
									<td><SmartTextBox text={execution.schedule.job.description}/></td>
									<td><ExecutionShortInfoControl execution={execution}/></td>
									<td className="text-nowrap">{formatDateForHumans(execution.startTime)}</td>
									<td><DurationControl ms={execution.durationMs}/></td>
									<td>
										<ExecutionStateControl state={execution.result.executionState}/>
									</td>
									<td>
										<ExecutionStateProgressControl
											direction="horizontal"
											executionState={execution.result.executionState}
											progress={execution.result.progress}
										/>
									</td>
									<td>
										{
											execution.errors > 0 ?
												<ExecutionMessagesModalButton
													eventManager={eventManager}
													executionId={execution.id}
													defaultMessageType="Error"
													buttonLabel={String(execution.errors)}
													buttonSize="sm"
												/>
												: execution.errors
										}
									</td>
									<td>
										{
											execution.warnings > 0 ?
												<ExecutionMessagesModalButton
													eventManager={eventManager}
													executionId={execution.id}
													defaultMessageType="Warning"
													buttonLabel={String(execution.warnings)}
													buttonSize="sm"
												/>
												: execution.warnings
										}
									</td>
									<td>{execution.worker?.name}</td>
									<td>{formatDateForHumans(execution.startWorkerTime)}</td>
									<td><DurationControl ms={execution.durationWorkerMs}/></td>
									<td>
										<ExecutionDetailModalButton
											buttonSize="sm"
											eventManager={eventManager}
											executionId={execution.id}
										/>
									</td>
								</tr>
							);
						})
				}
			</AdvancedTable>
		</div>
	);
}

export default ExecutionsTab;
