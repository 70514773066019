import React, {useEffect, useState} from 'react';
import {Badge, Stack} from "react-bootstrap";
import {ImportPluginJobArgument} from "../../../../type/ImportPluginJobArgument";
import {StringUtil} from "incomaker-react-ts-commons";
import {formatDateForHumans} from '../../../../util/dateUtil';

export type ExecutionArgsControlProps = {
	args?: string | null;
};

function ImportPluginArgsControl({args}: ExecutionArgsControlProps) {
	const [jobArg, setJobArg] = useState<ImportPluginJobArgument | null>(null)

	useEffect(
		() => {
			if (StringUtil.isEmpty(args)) {
				setJobArg(null);
				return;
			}
			setJobArg(JSON.parse(String(args)));
		},
		[args]
	);

	if (jobArg === null) return <></>

	return (
		<Stack direction="horizontal" gap={2} className="flex-wrap">
			<Badge bg="light" text="dark">{jobArg.workerParam.importCommand}</Badge>
			{
				jobArg.workerParam.itemId ? (
					<>
						<Badge bg="secondary">ID</Badge>
						<Badge bg="dark" text="light">{jobArg.workerParam.itemId}</Badge>
					</>
				) : (
					<>
						<Badge bg="secondary">{jobArg.workerParam.importMode}</Badge>
						{
							jobArg.workerParam.since && <Badge bg="dark" text="light">since: {formatDateForHumans(jobArg.workerParam.since)}</Badge>
						}
					</>
				)
			}
			{
				jobArg.workerParam.downloadLongDescriptions && <Badge bg="info" text="dark">Long Descriptions</Badge>
			}
			{
				jobArg.workerParam.forceSubscriptionReimport && <Badge bg="info" text="dark">Subscriptions</Badge>
			}
			{
				jobArg.workerParam.forceFixSex && <Badge bg="info" text="dark">Sex</Badge>
			}
			{
				jobArg.priorityMessage && <Badge bg="primary">Priority</Badge>
			}
			{
				jobArg.vipQueue && <Badge bg="warning" text="dark">VIP</Badge>
			}
		</Stack>
	)

}

export default ImportPluginArgsControl;
