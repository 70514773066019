import React from 'react';
import {Badge} from "react-bootstrap";

export type MessageTypeProps = {
	typ: string | null | undefined;
};

const TYPE_MAPPING: Map<string, string> = new Map<string, string>();
TYPE_MAPPING.set('Error', 'danger');
TYPE_MAPPING.set('Warning', 'warning');

export function getTypeVariant(executionState: string) {
	return TYPE_MAPPING.get(executionState) || 'primary';
}

function MessageTypeControl({typ}: MessageTypeProps) {
	if (!typ) return <></>;
	const background: string = getTypeVariant(typ);
	const text = ['warning', 'light'].includes(background) ? 'dark' : 'light';
	return <Badge bg={background} text={text}>{typ}</Badge>;
}

export default MessageTypeControl;
