import React, {RefObject} from "react";
import {Button, Form} from "react-bootstrap";
import {BasicComponentProps} from "../type/BasicComponentProps";

export type LogInFormProps = BasicComponentProps & {
	onLogIn: (apiKey: string) => any;
};

function LogInForm({onLogIn}: LogInFormProps) {
	const apiKeyInput: RefObject<HTMLInputElement> = React.createRef();

	return (
		<Form onSubmit={() => onLogIn(apiKeyInput.current?.value || '')}>
			<Form.Group className="mb-3" controlId="pluginId">
				<Form.Label>API Key</Form.Label>
				<Form.Control type="password" ref={apiKeyInput}>
				</Form.Control>
			</Form.Group>
			<Form.Group className="d-flex flex-row gap-2">
				<Button variant="success" type="submit">
					Log In
				</Button>
			</Form.Group>
		</Form>
	);
}

export default LogInForm;
