import React from 'react';
import {Table} from 'react-bootstrap';
import {WorkerDataCollection} from "../../../type/WorkerData";
import Loading from "../../controls/Loading";
import WorkerStateControl from "../../controls/worker/WorkerStateControl";
import {StringUtil} from "incomaker-react-ts-commons";
import DurationControl from "../../controls/DurationControl";
import {getSinceDurationMs} from "../../../util/dateUtil";
import WorkerJavaHeapControl from "../../controls/worker/WorkerJavaHeapControl";

export type WorkersOverviewProps = {
	workers: WorkerDataCollection | null;
};

function WorkersOverview({workers}: WorkersOverviewProps) {
	if (workers === null) return <Loading/>;

	if (workers.length === 0) return <span>No workers available.</span>

	return (
		<Table responsive className="cool-table">
			<tbody>
				{
					workers.map(
						(w) =>
							<tr key={w.name} className="align-items-center justify-content-center">
								<td className="w-auto vertical-align-center">
									<WorkerStateControl state={w.workerState}/>
								</td>
								<td className="w-auto vertical-align-center text-nowrap">
									<strong>{w.name}</strong>
								</td>
								<td className="w-auto vertical-align-center">
									<small><code>{w.version}</code></small>
								</td>
								<td className="w-auto vertical-align-center">
									<div style={{minWidth: 100}}>
										<WorkerJavaHeapControl stats={w.javaHeapStats}/>
									</div>
								</td>
								<td className="w-100 vertical-align-center">
									<small>
										{
											w.workerState === 'Dead' ? (
												<>
													<span>Died&nbsp;</span>
													<span><DurationControl ms={getSinceDurationMs(w.lastHeartbeat)}/>&nbsp;</span>
													<span>ago</span>
													{
														StringUtil.notEmpty(w.currentActivity) && w.currentActivity !== 'Zzz...' && (
															<span>&nbsp;when "{w.currentActivity}"</span>
														)
													}
												</>
											) : (
												<>{w.currentActivity}</>
											)
										}
									</small>
								</td>
							</tr>
					)
				}
			</tbody>
		</Table>
	);
}

export default WorkersOverview;
