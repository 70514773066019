import React, {useState} from 'react';
import {DEFAULT_PAGING, Paging} from "../../../../type/Paging";
import {clone} from "../../../../util/objectUtil";
import {Button, Modal, Stack} from 'react-bootstrap';
import {BsExclamationCircleFill} from 'react-icons/bs';
import ExecutionMessagesControl, {ExecutionMessagesControlProps} from "./ExecutionMessagesControl";
import {StringUtil} from "incomaker-react-ts-commons";

const DEFAULT_PAGING_ERRORS: Paging = clone(DEFAULT_PAGING);
DEFAULT_PAGING_ERRORS.sorting = [{name: "timestamp", desc: true}];
DEFAULT_PAGING_ERRORS.size = 5;

export type ExecutionErrorsModalProps = ExecutionMessagesControlProps & {
	buttonLabel?: string;
	buttonSize?: "lg" | "sm";
	buttonVariant?: string;
};

function ExecutionMessagesModalButton(props: ExecutionErrorsModalProps) {
	const [isVisible, setIsVisible] = useState<boolean>(false);

	return (
		<div>
			<Button
				onClick={() => setIsVisible(true)}
				variant={StringUtil.getNonEmpty(props.buttonVariant, props.defaultMessageType === 'Error' ? 'danger' : 'warning')}
				size={props.buttonSize}
			>
				<Stack direction="horizontal" gap={2}>
					{props.buttonLabel && props.buttonLabel}
					<BsExclamationCircleFill/>
				</Stack>
			</Button>
			{
				isVisible && (
					<Modal
						show={true}
						onHide={() => setIsVisible(false)}
						size="xl"
					>
						<Modal.Body>
							<ExecutionMessagesControl
								{...props}
							/>
						</Modal.Body>
						<Modal.Footer className="justify-content-center">
							<Button
								onClick={() => setIsVisible(false)}
								variant="secondary"
								className="m-auto"
							>
								Close
							</Button>
						</Modal.Footer>
					</Modal>
				)
			}
		</div>
	);
}

export default ExecutionMessagesModalButton;
