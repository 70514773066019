import React, {useEffect, useState} from 'react';
import {Button} from "react-bootstrap";
import {deleteSchedule, loadSchedules, runJobImmediately, saveSchedule} from "../../../util/restClient";
import {ScheduleData, SchedulePage} from "../../../type/ScheduleData";
import {BasicComponentProps} from "../../../type/BasicComponentProps";
import {DEFAULT_PAGING, Paging} from "../../../type/Paging";
import {clone} from "../../../util/objectUtil";
import TabMenu from "../../controls/TabMenu";
import AdvancedTable from "../../controls/AdvancedTable";
import Loading from "../../controls/Loading";
import ScheduleForm from "./ScheduleForm";
import {formatDateForHumans} from "../../../util/dateUtil";
import ExecutionStateControl from "../../controls/execution/state/ExecutionStateControl";
import {JobData} from "../../../type/JobData";
import {getCache, setCache} from "../../../util/cacheUtil";
import JobShortInfoControl from "../../controls/JobShortInfoControl";

const DEFAULT_PAGING_SCHEDULES: Paging = clone(DEFAULT_PAGING);
DEFAULT_PAGING_SCHEDULES.sorting = [{name: "lastExecutionTime", desc: true}];

function SchedulesTab({eventManager}: BasicComponentProps) {
	const defaultSearchText = getCache('default-search-text', () => '');
	const cachedPaging = getCache('paging-props-schedules', () => clone(DEFAULT_PAGING_SCHEDULES));
	cachedPaging.search = defaultSearchText;
	const [paging, setPaging] = useState<Paging>(cachedPaging);

	const [schedules, setSchedules] = useState<SchedulePage | null>(null);
	const [form, setForm] = useState<ScheduleData | null>(null);

	const search = (text: string) => {
		const newPaging = clone(paging);
		newPaging.search = text;
		setCache('paging-props-schedules', newPaging);
		setCache('default-search-text', text)
		setPaging(newPaging);
	}

	const refreshSchedules = () => {
		setSchedules(null);
		loadSchedules(paging)
			.then(
				setSchedules,
				(err) => eventManager.err(String(err))
			);
	}

	useEffect(refreshSchedules, [paging]);

	const onSaveForm = (data: ScheduleData) => {
		saveSchedule(data)
			.then(
				() => {
					setForm(null);
					refreshSchedules();
				},
				(err) => eventManager.err(String(err))
			);
	}

	const onDelete = (data: ScheduleData) => {
		if (!window.confirm("Really delete this schedule?")) {
			return;
		}
		deleteSchedule(Number(data.id))
			.then(
				() => {
					setForm(null);
					refreshSchedules();
				},
				(err) => eventManager.err(String(err))
			);
	}

	const runImmediately = (job: JobData) => {
		runJobImmediately(Number(job.id))
			.then(
				(schedule: ScheduleData) => {
					eventManager.success(`Started job ${job.name}. Spawned schedule of type Immediate (ID = ${schedule.id}).`);
					//props.onRefreshJobs();
				},
				(err) => eventManager.err(String(err))
			);
	};

	const header = [
		{name: 'id', label: 'ID'},
		{name: 'job.pluginId', label: 'Plugin'},
		{name: 'job.description', label: 'Description'},
		{name: 'job.name', label: 'Job'},
		{name: 'scheduleType', label: 'Type'},
		{name: 'isActive', label: 'Active'},
		{name: '', label: 'Scheduling'},
		{name: 'lastExecutionTime', label: 'Last Executed'},
		{name: 'lastExecutionState', label: 'Last Result'},
		{name: '', label: 'Next Execution'},
		{name: '', label: ''},
	];

	return (
		<div>
			<TabMenu searchText={paging.search} onRefreshButtonClick={refreshSchedules} onSearchButtonClick={search}>
			</TabMenu>
			<AdvancedTable
				header={header}
				onPagingChanged={setPaging}
				totalPages={schedules ? schedules.totalPages : 0}
				totalItems={schedules ? schedules.totalElements : 0}
				paging={paging}
			>
				{
					(schedules === null) ? <tr key="none">
							<td colSpan={header.length}><Loading/></td>
						</tr> :
						schedules.content.map((schedule, index) => {
							return (
								<tr key={index}>
									<td>{schedule.data.id}</td>
									<td>{schedule.data.job.pluginId}</td>
									<td>
										<small>
											<pre>{schedule.data.job.description}</pre>
										</small>
									</td>
									<td><JobShortInfoControl job={schedule.data.job}/></td>
									<td>{schedule.data.scheduleType}</td>
									<td>{schedule.data.active ? 'Yes' : 'No'}</td>
									<td>{schedule.schedulingDescription}</td>
									<td>{formatDateForHumans(schedule.data.lastExecutionTime)}</td>
									<td><ExecutionStateControl state={schedule.data.lastExecutionState}/></td>
									<td>{formatDateForHumans(schedule.nextExecutionTime)}</td>
									<td>
										<div className="d-flex gap-2">
											<Button variant="primary" size="sm" onClick={() => setForm(schedule.data)}>Edit...</Button>
											<Button variant="success" size="sm"
													onClick={() => runImmediately(schedule.data.job)}>Run&nbsp;Now</Button>
										</div>
									</td>
								</tr>
							);
						})
				}
			</AdvancedTable>
			{
				form ?
					<ScheduleForm
						data={form}
						onSaved={onSaveForm}
						onCancelled={() => setForm(null)}
						onDeleted={onDelete}
						eventManager={eventManager}
					/>
					: <></>
			}
		</div>
	);
}

export default SchedulesTab;
