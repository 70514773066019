const cache: { [key: string]: any } = {};

export function setCache(key: string, value: any): any {
	cache[key] = value;
	return value;
}

export function getCache(key: string, valueProvider: () => any): any {
	const result = cache[key];
	if (result === undefined) return setCache(key, valueProvider());
	return result;
}
